<template>
  <div>
    <NodePageLanding v-if="node" v-bind="node" />
  </div>
</template>

<script lang="ts" setup>
import type { NodePageFragment } from '#graphql-operations'

defineOptions({
  name: 'LandingPage',
})

definePageMeta({
  name: 'landing-page-at',
  drupalFrontendRoute: true,
  languageMapping: {
    de: '/de/oesterreich',
    fr: '/fr/autriche',
    en: '/en/austria',
  },
  hideBreadcrumb: true,
})

const nuxtRoute = useRoute()

// Get the data.
const { data: query } = await useAsyncData(nuxtRoute.path, async () => {
  return await useGraphqlQuery('route', {
    path: nuxtRoute.path,
  }).then((v) => {
    return v.data
  })
})

// Handles redirects and metatags.
const { entity: node } = await useDrupalRoute<NodePageFragment>(query.value)

setBreadcrumbLinksFromRoute(query.value)
setBusinessAreaFromRoute(query.value)
setLanguageLinksFromRoute(query.value)
await renderPageDependencies()
</script>
